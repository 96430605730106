<template>
  <v-app-bar
    color="#fff"
    clipped-left
    clipped-right
    app>
    <div class="snt-template-action d-flex align-center gap-2 py-2 px-3">
      <div class="snt-template-action__viewpages">
        <v-select
          :value="viewPage"
          :items="htmlPages"
          item-text="name"
          item-value="key"
          background-color="#ffffff"
          hide-details
          outlined
          dense
          @input="setViewPage($event)"
          @click="setMini(true)" />
      </div>
      <design-menu />
      <v-spacer />
      <v-btn
        :outlined="device !== 'laptop'"
        class=" px-0"
        min-width="40px"
        color="primary"
        depressed
        @click="setDevice('laptop')">
        <v-icon>$snt_desktop_monitor</v-icon>
      </v-btn>
      <v-btn
        :outlined="device !== 'tablet'"
        class=" px-0"
        min-width="40px"
        color="primary"
        depressed
        @click="setDevice('tablet')">
        <v-icon>mdi-tablet</v-icon>
      </v-btn>
      <v-btn
        :outlined="device !== 'mobile'"
        class=" px-0"
        min-width="40px"
        color="primary"
        depressed
        @click="setDevice('mobile')">
        <v-icon>$snt_cell_phone</v-icon>
      </v-btn>
      <v-divider
        vertical />
      <v-btn
        color="primary"
        text>
        Document
      </v-btn>
      <v-divider
        vertical />
      <v-btn
        v-if="coding"
        class="snt-template__btn "
        color="primary"
        outlined
        depressed
        @click="setCoding(false)">
        {{ $t('preview') }}
      </v-btn>
      <v-btn
        v-else
        class=""
        color="primary"
        outlined
        depressed
        @click="setCoding(true)">
        เขียนโค้ด
      </v-btn>
      <!-- <v-btn
      :to="{ name: 'Editor', params: { slug: templateId } }"
      class=""
      color="primary"
      target="_blank"
      outlined
      depressed>
      {{ $t('newTab') }}
    </v-btn> -->
      <!-- <v-btn
      :disabled="!valid"
      :loading="submitting"
      class="snt-template__btn "
      color="primary"
      depressed
      outlined
      @click="submit()">
      {{ $t('save') }}
    </v-btn> -->
      <save-all-button @submit="$emit('action')" />
      <!-- <v-btn
      class="snt-template__btn "
      color="primary"
      outlined
      depressed
      @click="setCoding(false)">
      {{ $t('preview') }}
    </v-btn> -->
      <publish-button />
    </div>
  </v-app-bar>
</template>

<script>
import { VueXTemplate } from '@shipnity/template/src/utils/dom'
import { UPDATE_SALEPAGETEMPLATE } from '@/resources/graphql'
import { mapActions, mapGetters } from 'vuex'
import SaveAllButton from './SaveAllButton.vue'
import PublishButton from './PublishButton.vue'
import DesignMenu from './DesignMenu.vue'

export default {
  components: { SaveAllButton, PublishButton, DesignMenu },
  data () {
    return {
      submitting: false,
      device: 'laptop',
      viewPages: [
        {
          text: this.$t('home'),
          value: 'landing'
        },
        {
          text: this.$t('productItems'),
          value: 'products'
        },
        {
          text: this.$t('productDesc'),
          value: 'product'
        },
        {
          text: this.$t('productCart'),
          value: 'cart'
        },
        {
          text: this.$t('checkout'),
          value: 'checkout'
        },
        {
          text: this.$t('payment'),
          value: 'payment'
        },
        {
          text: this.$t('orders'),
          value: 'orders'
        },
        {
          text: this.$t('order'),
          value: 'order'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      viewPage: 'app/viewPage',
      currentFileObj: 'template/currentFileObj',
      files: 'template/files',
      pages: 'template/pages',
      coding: 'app/coding'
    }),
    valid () {
      return this.currentFileObj && this.currentFileObj.isDirty
    },
    templateId () {
      return this.$route.params.tid
    },
    previewUrl () {
      return this.getUrl(this.viewPage)
    },
    htmlPages () {
      return this.pages.filter((item) => !['header', 'footer'].includes(item.key) && item.lang === 'html')
    }
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  created () {
    window.addEventListener('keydown', this.handleKeydown)
  },
  methods: {
    ...mapActions({
      setViewPage: 'app/setViewPage',
      setMini: 'app/setMini',
      setCoding: 'app/setCoding',
      updateFile: 'template/updateFile'
    }),
    setDevice (device) {
      this.device = device
    },
    getUrl (viewPage) {
      const templateUrl = `${process.env.VUE_APP_ROUTE_PREFIX}templates/${this.templateId}/sale-page`
      const templateUrlSuffix = ''
      switch (viewPage) {
        case 'Products':
          return `${templateUrl}/products${templateUrlSuffix}`
        case 'ProductDetail':
          return `${templateUrl}/products/default${templateUrlSuffix}`
        case 'Cart':
          return `${templateUrl}/cart${templateUrlSuffix}`
        case 'Checkout':
          return `${templateUrl}/checkout${templateUrlSuffix}`
        case 'Payment':
          return `${templateUrl}/payment${templateUrlSuffix}`
        case 'Order':
          return `${templateUrl}/orders/default${templateUrlSuffix}`
        case 'Orders':
          return `${templateUrl}/orders${templateUrlSuffix}`
        default:
          return `${templateUrl}/${templateUrlSuffix}`
      }
    },
    handleKeydown (e) {
      if (e.ctrlKey) {
        this.holdCtrl = true
        switch (e.key.toLowerCase()) {
          case 's':
            e.preventDefault()
            this.submit()
            break
          default:
            break
        }
      }
    },
    setTemplateCompute (template) {
      return new VueXTemplate({
        template,
        data: {
          theme: {
            color: {
              primary: '#1976D2',
              secondary: '#424242',
              accent: '#82B1FF'
            }
          }
        }
      }).compute()
    },
    async submit () {
      if (!this.valid) return
      this.submitting = true
      const file = this.currentFileObj
      const { keys, values } = this.setTemplateCompute(file.code)
      const input = file.key !== 'stylesheet' ? {
        [file.key]: file.code,
        [`${file.key}Keys`]: keys,
        [`${file.key}Values`]: values
      } : {
        [file.key]: file.code
      }
      await this.$apollo.mutate({
        mutation: UPDATE_SALEPAGETEMPLATE,
        variables: {
          input: {
            id: this.templateId,
            ...input
          }
        }
      })
      this.updateFile({
        ...this.currentFileObj,
        isDirty: false
      })
      this.$notify.success('บันทึกสำเร็จ')
      this.submitting = false
      this.setCoding(false)
      this.$emit('action')
    }
  }
}
</script>

<style scoped>
  .snt-template-action {
    width: 100%;
  }
  .snt-template-action__action  {
    height: 56px;
    border-bottom: 1px solid #E4E4E4;
  }
  .snt-template-action__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .snt-template-action__container--tablet {
    width: 768px !important;
    margin: 0 auto !important;
  }
  .snt-template-action__container--mobile {
    width: 320px !important;
    margin: 0 auto !important;
  }
  .snt-template-action__arrows {
    margin-right: 8px;
  }
  .snt-template-action__viewpages {
    width: 200px;
  }
  .snt-template-action {
    width: 100%;
    padding-left: 8px;
  }
  .snt-template-action__icon-discard {
    transform: rotate(180deg);
  }
  .snt-template__btn {
    background-color: #ffffff;
  }
</style>
