<template>
  <div class="snt-template-code">
    <!-- <v-layout class="snt-template-code__tabs">
      <v-flex lg6>
        <v-tabs
          :value="tab"
          :ripple="false"
          active-class="snt-template-code__tab--active"
          class="snt-template-code-tabs"
          background-color="transparent">
          <v-tab
            v-for="(file, i) in files"
            :key="`tab-${i}`"
            :ripple="false"
            class="snt-template-code__tab pl-4 pr-2"
            @click="setCurrentFile(file.key)">
            <div class="d-flex align-center">
              <div class="snt-template-code__tab--capitalize mr-4">
                {{ file.name }}
              </div>
              <v-spacer />
              <div class="snt-template-code__dirty">
                <v-icon
                  v-if="file.isDirty"
                  size="8">
                  mdi-record
                </v-icon>
              </div>
              <div>
                <code-close-confirm
                  v-if="file.isDirty"
                  @submit="handleCloseFile(file, $event)">
                  <v-btn
                    icon
                    x-small>
                    <v-icon small>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </code-close-confirm>
                <v-btn
                  v-else
                  icon
                  x-small
                  @click.stop="onCloseFile(file, i)">
                  <v-icon small>
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-tab>
        </v-tabs>
      </v-flex>
      <v-flex lg6>
        <action-bar />
      </v-flex>
    </v-layout> -->
    <div class="snt-template-code__container">
      <codemirror
        :value="file.code"
        :options="codemirrorOption[file.lang]"
        @input="debounceChangeCode(file, $event)" />
      <!-- <v-tabs-items :value="currentFile">
        <v-tab-item
          v-for="(file) in files"
          :key="`file-${file.key}`"
          :value="file.key"
          transition="none">
          <codemirror
            :value="file.code"
            :options="codemirrorOption[file.lang]"
            @input="debounceChangeCode(file, $event)" />
        </v-tab-item>
      </v-tabs-items> -->
      <!-- <div
        v-if="!files.length"
        class="d-flex justify-center align-center h-100">
        <div class="text-center pb-14">
          <v-icon
            color="#6C6C6C"
            size="80">
            mdi-file-code-outline
          </v-icon>
          <div class="ma-3">
            <v-icon>mdi-arrow-left</v-icon>
            {{ $t('openFile') }}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      codemirrorOption: {
        html: {
          tabSize: 4,
          styleActiveLine: true,
          lineNumbers: true,
          autoCloseTags: true,
          line: true,
          lineWrapping: true,
          mode: 'text/html',
          keyMap: 'sublime'
        },
        css: {
          tabSize: 4,
          styleActiveLine: true,
          lineWrapping: true,
          lineNumbers: true,
          line: true,
          mode: 'text/css',
          keyMap: 'sublime'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentFile: 'template/currentFile',
      viewPage: 'app/viewPage',
      pages: 'template/pages',
      files: 'template/files'
    }),
    file () {
      return this.pages.find((item) => item.key === this.viewPage)
    },
    tab () {
      return this.currentFile ? _.findIndex(this.files, ['key', this.currentFile]) : null
    }
  },
  created () {
    this.debounceChangeCode = _.debounce(this.changeCode, 150)
  },
  methods: {
    ...mapActions({
      setCurrentFile: 'template/setCurrentFile',
      updateFile: 'template/updateFile',
      closeFile: 'template/closeFile'
    }),
    onCloseFile (file, index) {
      const currentTab = this.tab
      const currentFile = this.currentFile
      const isLastTab = index === this.files.length - 1
      this.closeFile(file)
      const prevIndex = _.findIndex(this.files, ['key', currentFile])
      if (this.files.length) {
        if (isLastTab) {
          const prevFile = this.files[0]?.key || null
          this.setCurrentFile(prevFile)
        } else if (prevIndex === -1) {
          const prevFile = this.files[currentTab]?.key || this.files[this.files.length - 1].key
          this.setCurrentFile(prevFile)
        }
      } else {
        this.setCurrentFile(null)
      }
    },
    handleCloseFile (file, type) {
      if (type === 'close') {
        this.closeFile(file)
      }
    },
    changeCode (file, code) {
      this.updateFile({
        ...file,
        code,
        isDirty: true
      })
    }
  }
}
</script>

<style>
  .CodeMirror {
    height: auto;
  }
  .snt-template-code-tabs .v-item-group {
    height: 40px !important;
  }
</style>
<style scoped>
  .snt-template-code {
    height: 100%;
    width: 100%;
    /* width: 60%; */
  }
  .snt-template-code__tabs {
    display: flex;
    height: 56px;
    padding: 10px 12px 0 16px;
    background: #EBEBEB;
    border-bottom: 1px solid #E4E4E4;
    overflow-x: auto;
  }
  .snt-template-code-tabs {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
  .snt-template-code__tab {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background:#EBEBEB;
  }
  .snt-template-code__dirty {
    width: 8px;
    margin: 0 4px;
  }
  .snt-template-code__tab--capitalize {
    text-transform: capitalize;
  }
  .snt-template-code__tab::before {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .snt-template-code__tab--active {
    background:#ffffff;
  }
  .snt-template-code__container {
    width: 100%;
    /* height: calc(100% - 56px); */
    background-color: #ffffff;
    overflow: auto;
  }
  .vue-codemirror {
    height: 100%;
  }
</style>
