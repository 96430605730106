<template>
  <v-dialog
    v-model="dialog"
    width="320px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="snt-btn--rounded"
        color="primary"
        depressed
        v-on="on">
        {{ $t('publish.btn') }}
      </v-btn>
    </template>
    <v-card class="snt-card--rounded">
      <v-card-title class="pt-6 px-6 align-start">
        <div class="font-size-18 font-weight-medium">
          <div>เลือกเวอร์ชั่นของ</div>
          <div>การเปลี่ยนแปลง</div>
        </div>
        <v-spacer />
        <v-btn
          x-small
          icon
          @click="setDialog(false)">
          <v-icon size="14">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-6">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-radio-group
            v-model="versionUpdateType"
            :rules="rule.versionUpdateType"
            class="mt-0"
            hide-details
            dense
            column>
            <v-radio
              v-for="item in versionUpdateTypeEnum"
              :key="`version-type-${item.name}`"
              :label="$t(item.description)"
              :value="item.name" />
          </v-radio-group>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-6 px-6">
        <v-spacer />
        <v-btn
          class="snt-template-publish__btn px-4"
          text
          @click="setDialog(false)">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="submitting"
          class="snt-template-publish__btn px-4"
          color="primary"
          depressed
          @click="submit()">
          {{ $t('publish.btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import {
  GET_SALEPAGETEMPLATEVERSIONUPDATETYPE_ENUM,
  REVIEW_SALEPAGETEMPLATE
} from '@/resources/graphql'

export default {
  apollo: {
    versionUpdateTypeEnum: () => ({
      query: GET_SALEPAGETEMPLATEVERSIONUPDATETYPE_ENUM,
      update: (data) => data.__type.enumValues,
      result ({ data: { __type } }) {
        const head = _.head(__type.enumValues)
        if (head) {
          this.versionUpdateType = head.name
        }
      }
    })
  },
  data () {
    return {
      valid: false,
      dialog: false,
      submitting: false,
      rule: {
        versionUpdateType: [
          (v) => !!v || this.$t('required')
        ]
      },
      versionUpdateTypeEnum: [],
      versionUpdateType: null
    }
  },
  computed: {
    templateId () {
      return this.$route.params.tid
    }
  },
  methods: {
    async submit () {
      if (this.$refs.form.validate()) {
        this.submitting = true
        await this.$apollo.mutate({
          mutation: REVIEW_SALEPAGETEMPLATE,
          variables: {
            input: {
              id: this.templateId,
              updateType: this.versionUpdateType
            }
          }
        })
        this.submitting = false
        this.$notify.success('เผยแพร่สำเร็จ')
        this.setDialog(false)
      }
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>

<style scoped>
  .snt-template-publish__btn {
    border-radius: 8px;
  }
</style>
