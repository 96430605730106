<template>
  <v-navigation-drawer
    :value="mini"
    class="snt-template-files"
    clipped
    app>
    <div class="d-flex flex-column pa-2 pb-5 h-100">
      <v-list
        class="pt-0"
        rounded
        dense>
        <v-list-item class="px-2">
          <v-list-item-title>
            MANAGE PAGES
          </v-list-item-title>
          <v-btn
            icon
            x-small
            @click.stop="setMini(!mini)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item>
        <v-list-item
          :ripple="false"
          @click="selectPage(headPage)">
          <v-list-item-content>
            <v-list-item-title>
              Header
              <span class="mx-2 grey--text caption">(Layout)</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="my-0">
            <v-btn
              :ripple="false"
              small
              icon>
              <v-icon
                color="grey lighten-1"
                small>
                mdi-cog
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <template v-for="page in groupPages">
          <template v-if="page.module">
            <v-list-item
              :key="`page-${page.key}`"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ page.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="my-0">
                <v-btn
                  :ripple="false"
                  small
                  icon>
                  <v-icon
                    color="grey lighten-1"
                    small>
                    mdi-cog
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list
              :key="`subpage-${page.key}`"
              class="ml-6">
              <v-list-item
                v-for="child in page.pages"
                :key="`child-page-${child.key}`"
                link
                @click="selectPage(child)">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
          <v-list-item
            v-else
            :key="`page-${page.key}`"
            :ripple="false"
            @click="selectPage(page)">
            <v-list-item-content>
              <v-list-item-title>
                {{ page.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="my-0">
              <v-btn
                :ripple="false"
                small
                icon>
                <v-icon
                  color="grey lighten-1"
                  small>
                  mdi-cog
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-list-item
          :ripple="false"
          @click="selectPage(footPage)">
          <v-list-item-content>
            <v-list-item-title>
              Footer
              <span class="mx-2 grey--text caption">(Layout)</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="my-0">
            <v-btn
              :ripple="false"
              small
              icon>
              <v-icon
                color="grey lighten-1"
                small>
                mdi-cog
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-spacer />
      <div class="text-center">
        <v-btn
          color="primary"
          outlined
          rounded
          @click="addPage()">
          <v-icon left>
            mdi-plus
          </v-icon>
          Add Page
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    const files = []
    const res = require.context('@/resources/json/documents/', true, /\.json$/)
    res.keys().forEach((key) => {
      files.push(res(key))
    })
    return {
      drawer: true
    }
  },
  computed: {
    ...mapGetters({
      mini: 'app/mini',
      name: 'template/name',
      pages: 'template/pages',
      viewPage: 'app/viewPage'
    }),
    htmlPages () {
      return this.pages.filter((item) => !['header', 'footer'].includes(item.key) && item.lang === 'html')
    },
    groupPages () {
      let pages = []
      const groups = this.$_.groupBy(this.htmlPages, 'module')
      this.$_.forEach(groups, (value, key) => {
        if (key === 'undefined') {
          pages = this.$_.concat(pages, value)
        } else {
          pages = this.$_.concat({
            module: key,
            name: key,
            pages: value
          }, pages)
        }
      })
      return pages
    },
    headPage () {
      return this.pages.find((item) => item.key === 'header')
    },
    footPage () {
      return this.pages.find((item) => item.key === 'footer')
    }
  },
  methods: {
    ...mapActions({
      setMini: 'app/setMini',
      setCoding: 'app/setCoding',
      openFile: 'template/openFile',
      addPages: 'template/addPages',
      setCurrentPage: 'template/setCurrentPage',
      setViewPage: 'app/setViewPage'
    }),
    selectFile (file) {
      this.openFile(file)
      this.setCoding(false)
    },
    selectPage (page) {
      this.setViewPage(page.key)
      this.setCurrentPage(page)
    },
    addPage () {
      this.addPages([{
        key: `blank-page-${this.pages.length}`,
        code: '<snt-app><snt-section name="Blank">x  x</snt-section></snt-app>',
        name: 'Blank Page',
        lang: 'html'
      }])
    }
  }
}
</script>

<style lang="scss" scoped>
  .snt-template-files {
    min-width: 256px;
  }
  .snt-template-files.v-navigation-drawer--mini-variant {
    min-width: 56px;
  }
  .snt-template-filegroup .v-list-group__header__prepend-icon {
    margin-right: 16px !important;
  }
  .snt-template-filegroup .v-list-group__header__append-icon {
    margin: 0 !important;
  }
  .snt-template-filegroup .v-list-group__items > .v-list-item {
    padding-left: 56px !important;
  }
  .v-list-group--no-action > .v-list-group__items > .v-list-item {
    padding-left: 36px !important;
  }
</style>
