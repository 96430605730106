<template>
  <div class="text-center">
    <v-menu
      transition="slide-y-transition"
      open-on-hover
      bottom
      offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          v-on="on">
          Design
          <v-icon right>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :ripple="false"
          link
          @click="setDesigning(true);setMini(false)">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      items: [
        { title: 'Stylesheet' },
        { title: 'Color' },
        { title: 'Font' }
      ]
    }
  },
  methods: {
    ...mapActions({
      setDesigning: 'app/setDesigning',
      setMini: 'app/setMini'
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
