<template>
  <div class="snt-template">
    <ActionBar />
    <div class="snt-template__container">
      <files />
      <v-main class="snt-template-editor">
        <x-code v-if="coding" />
        <x-preview
          v-else
          :timestamp="timestamp" />
      </v-main>
      <designs />
    </div>
  </div>
</template>

<script>
import { GET_SALEPAGETEMPLATE } from '@/resources/graphql'
import { mapActions, mapGetters } from 'vuex'
import Files from './components/Files.vue'
import XCode from './components/XCode.vue'
import XPreview from './components/XPreview.vue'
import ActionBar from './components/ActionBar.vue'
import Designs from './components/Designs.vue'

export default {
  components: {
    Files,
    ActionBar,
    XPreview,
    XCode,
    Designs
  },
  apollo: {
    salePageTemplate: () => ({
      query: GET_SALEPAGETEMPLATE,
      fetchPolicy: 'network-only',
      skip () {
        return this.templateId === 'create'
      },
      variables () {
        return {
          id: this.templateId
        }
      },
      watchLoading (isLoading) {
        this.isLoading = isLoading
      },
      result ({ data: { salePageTemplate } }) {
        this.setName(salePageTemplate.name)
        this.addPages([
          {
            key: 'header',
            code: salePageTemplate.header,
            name: 'Header',
            lang: 'html'
          },
          {
            key: 'landing',
            code: salePageTemplate.landing,
            name: 'Landing',
            lang: 'html'
          },
          {
            key: 'products',
            code: salePageTemplate.products,
            name: 'Products',
            lang: 'html',
            module: 'shop'
          },
          {
            key: 'product',
            code: salePageTemplate.product,
            name: 'Product',
            lang: 'html',
            module: 'shop'
          },
          {
            key: 'cart',
            code: salePageTemplate.cart,
            name: 'Cart',
            lang: 'html',
            module: 'shop'
          },
          {
            key: 'checkout',
            code: salePageTemplate.checkout,
            name: 'Checkout',
            lang: 'html',
            module: 'shop'
          },
          {
            key: 'payment',
            code: salePageTemplate.payment,
            name: 'Payment',
            lang: 'html',
            module: 'shop'
          },
          {
            key: 'order',
            code: salePageTemplate.order,
            name: 'Order',
            lang: 'html',
            module: 'shop'
          },
          {
            key: 'orders',
            code: salePageTemplate.orders,
            name: 'Orders',
            lang: 'html',
            module: 'shop'
          },
          {
            key: 'footer',
            code: salePageTemplate.footer,
            name: 'Footer',
            lang: 'html'
          },
          {
            key: 'stylesheet',
            code: salePageTemplate.stylesheet,
            name: 'Stylesheet',
            lang: 'css'
          }
        ])
        this.resolveFiles()
      }
    })
  },
  data () {
    return {
      timestamp: +new Date()
    }
  },
  computed: {
    ...mapGetters({
      mini: 'app/mini',
      coding: 'app/coding'
    }),
    templateId () {
      return this.$route.params.tid
    }
  },
  watch: {
    codeing () {
      this.timestamp = +new Date()
    }
  },
  created () {
    this.clearFiles()
  },
  methods: {
    ...mapActions({
      setName: 'template/setName',
      clearFiles: 'template/clearFiles',
      addPages: 'template/addPages',
      resolveFiles: 'template/resolveFiles'
    })
  }
}
</script>

<style scoped>
  .snt-template {
    height: 100vh;
  }
  .snt-template__container {
    display: flex;
    height: calc(100vh - 64px);
  }
  .snt-template-editor {
    /* width: calc(100% - 256px); */
    width: 100%;
    height: 100%;
  }
  .snt-template-editor.snt-template-editor--mini-variant {
    width: calc(100% - 56px);
  }
</style>
