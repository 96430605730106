<template>
  <v-dialog
    v-model="dialog"
    width="320px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :disabled="!canSave"
        :loading="submitting"
        class="snt-template__btn snt-btn--rounded"
        color="primary"
        depressed
        outlined
        v-on="on">
        {{ $t('saveAll') }}
      </v-btn>
    </template>
    <v-card class="snt-card--rounded">
      <v-card-title class="pt-6 px-6 pb-0"></v-card-title>
      <v-card-text class="px-6">
        <div class="snt-template-saveall__action">
          <v-btn
            x-small
            icon
            @click="setDialog(false)">
            <v-icon
              color="#6C6C6C"
              size="14">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="text-center mb-4">
          <v-icon
            size="53"
            color="primary">
            $snt_save_outline_2
          </v-icon>
        </div>
        <div class="snt-template-saveall__title">
          {{ $t('wanttosave') }}
        </div>
      </v-card-text>
      <v-card-actions class="pb-6 px-6">
        <v-spacer />
        <v-btn
          class="snt-template-saveall__btn px-4"
          text
          @click="setDialog(false)">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          class="snt-template-saveall__btn px-4"
          color="primary"
          depressed
          @click="submit()">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueXTemplate } from '@shipnity/template/src/utils/dom'
import { UPDATE_SALEPAGETEMPLATE } from '@/resources/graphql'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      submitting: false
    }
  },
  computed: {
    ...mapGetters({
      currentFileObj: 'template/currentFileObj',
      files: 'template/files'
    }),
    canSave () {
      return this.files.some((v) => v.isDirty)
    },
    templateId () {
      return this.$route.params.tid
    }
  },
  methods: {
    ...mapActions({
      setCoding: 'app/setCoding',
      updateFile: 'template/updateFile'
    }),
    setTemplateCompute (template) {
      return new VueXTemplate({
        template,
        data: {
          theme: {
            color: {
              primary: '#1976D2',
              secondary: '#424242',
              accent: '#82B1FF'
            }
          }
        }
      }).compute()
    },
    async submit () {
      this.submitting = true
      let input = {
        id: this.templateId
      }
      this.files
      .forEach((file) => {
        const { keys, values } = this.setTemplateCompute(file.code)
        input = this.$_.assignIn(input,
          file.key !== 'stylesheet' ? {
            [file.key]: file.code,
            [`${file.key}Keys`]: keys,
            [`${file.key}Values`]: values
          } : {
            [file.key]: file.code
          })
      })
      await this.$apollo.mutate({
        mutation: UPDATE_SALEPAGETEMPLATE,
        variables: {
          input
        }
      })
      this.files.forEach((file) => {
        this.updateFile({
          ...file,
          isDirty: false
        })
      })
      this.$notify.success('บันทึกสำเร็จ')
      this.submitting = false
      this.$emit('submit')
      this.setCoding(false)
      this.setDialog(false)
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>

<style scoped>
  .snt-template-saveall__action {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .snt-template-saveall__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #6B6B6B;
  }
  .snt-template-saveall__btn {
    border-radius: 8px;
  }
  .snt-template__btn {
    background-color: #ffffff;
  }
</style>
