<template>
  <div class="snt-template-preview">
    <div class="d-flex gap-3 pa-1 px-3">
      <v-icon
        :style="{ width: '8px' }"
        x-large>
        mdi-circle-small
      </v-icon>
      <v-icon
        :style="{ width: '8px' }"
        x-large>
        mdi-circle-small
      </v-icon>
      <v-icon
        :style="{ width: '8px' }"
        x-large>
        mdi-circle-small
      </v-icon>
      <v-text-field
        :value="'www.shipnityshop.com'+url"
        :height="32"
        readonly
        hide-details
        rounded
        outlined
        dense />
    </div>
    <div
      :class="{
        'snt-template-preview__container--tablet': device === 'tablet',
        'snt-template-preview__container--mobile': device === 'mobile',
      }"
      class="snt-template-preview__container">
      <iframe
        :key="`iframe-preview-${timestamp}`"
        :src="previewUrl"
        class="snt-template-preview__iframe snt-template-preview__iframe--zoomout"
        frameborder="0"
        allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'XPreview',
  props: {
    timestamp: {
      type: [Number, String],
      default: +new Date()
    }
  },
  data () {
    return {
      device: 'laptop',
      viewPages: [
        {
          text: this.$t('home'),
          value: 'Home'
        },
        {
          text: this.$t('productItems'),
          value: 'Products'
        },
        {
          text: this.$t('productDesc'),
          value: 'ProductDetail'
        },
        {
          text: this.$t('productCart'),
          value: 'Cart'
        },
        {
          text: this.$t('checkout'),
          value: 'Checkout'
        },
        {
          text: this.$t('payment'),
          value: 'Payment'
        },
        {
          text: this.$t('orders'),
          value: 'Orders'
        },
        {
          text: this.$t('order'),
          value: 'Order'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      viewPage: 'app/viewPage',
      currentPage: 'template/currentPage'
    }),
    templateId () {
      return this.$route.params.tid
    },
    url () {
      switch (this.viewPage) {
        case 'landing':
          return ''
        case 'products':
          return '/products'
        case 'product':
          return '/products/default'
        case 'cart':
          return '/cart'
        case 'checkout':
          return '/checkout'
        case 'payment':
          return '/payment'
        case 'order':
          return '/orders/default'
        case 'orders':
          return '/orders'
        default:
          return `/pages/${this.viewPage}`
      }
    },
    previewUrl () {
      const templateUrl = `${process.env.VUE_APP_ROUTE_PREFIX}templates/${this.templateId}/sale-page`
      const templateUrlSuffix = ''
      return `${templateUrl}${this.url}${templateUrlSuffix}`
    }
  },
  methods: {
    ...mapActions({
      setViewPage: 'app/setViewPage',
      setCoding: 'app/setCoding'
    }),
    setDevice (device) {
      this.device = device
    }
  }
}
</script>

<style scoped>
  .snt-template-preview {
    height: calc(100% - 36px);
    /* width: 100%; */
    /* width: 40%; */
    margin: 18px 32px;
    border-left: 1px solid #E4E4E4;
    background-color: #ffffff;
  }
  .snt-template-preview__action  {
    height: 56px;
    border-bottom: 1px solid #E4E4E4;
  }
  .snt-template-preview__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .snt-template-preview__container--tablet {
    width: 768px !important;
    margin: 0 auto !important;
  }
  .snt-template-preview__container--mobile {
    width: 320px !important;
    margin: 0 auto !important;
  }
  .snt-template-preview__arrows {
    margin-right: 8px;
  }
  .snt-template-preview__viewpages {
    width: 200px;
  }
  .snt-template-preview__iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .snt-template-preview__iframe--zoomout {
    width: 124.925% !important;
    height: 124.925% !important;
    -ms-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  @media (max-width: 1440px) {
    .snt-template-preview__iframe--zoomout {
      width: 143% !important;
      height: 143% !important;
      -ms-transform: scale(0.70) !important;
      -moz-transform: scale(0.70) !important;
      -o-transform: scale(0.70) !important;
      -webkit-transform: scale(0.70) !important;
      transform: scale(0.70) !important;
    }
  }
</style>
