<template>
  <v-navigation-drawer
    :value="designing"
    width="480"
    class="snt-template-files"
    clipped
    right
    app>
    <div class="d-flex flex-column pa-2 h-100">
      <v-list
        class="pt-0"
        rounded
        dense>
        <v-list-item class="px-2">
          <v-list-item-title>
            MANAGE DESIGN
          </v-list-item-title>
          <v-btn
            icon
            x-small
            @click.stop="setDesigning(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
      <div class="overflow-auto">
        <codemirror
          v-model="stylesheet"
          :options="codemirrorOption" />
      </div>
      <v-spacer />
      <div class="text-center xxx">
        <v-btn
          color="primary"
          depressed
          rounded>
          Apply
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      drawer: true,
      stylesheet: '',
      codemirrorOption: {
        tabSize: 4,
        styleActiveLine: true,
        lineWrapping: true,
        lineNumbers: true,
        line: true,
        mode: 'text/css',
        keyMap: 'sublime'
      }
    }
  },
  computed: {
    ...mapGetters({
      designing: 'app/designing',
      pages: 'template/pages'
    }),
    file () {
      return this.pages.find((item) => item.key === 'stylesheet')
    }
  },
  watch: {
    file (value) {
      this.stylesheet = value.code || ''
    }
  },
  methods: {
    ...mapActions({
      setDesigning: 'app/setDesigning'
    })
  }
}
</script>

<style lang="scss" scoped>
  .xxx {
    background-color: #ffffff;
    padding: 8px;
    border-top: 1px solid #ddd;
  }
</style>
